import React from 'react';
import { classes } from './CartConflictMessage.st.css';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { useExperiments, useTranslation, Trans } from '@wix/yoshi-flow-editor';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { FormError } from '../../../../types/errors';
import { TextButton } from 'wix-ui-tpa/cssVars';
import { CartConflictMessageDataHooks } from './dataHooks';

export interface CartConflictMessageProps {
  error: FormError;
}

export const CartConflictMessage: React.FC<CartConflictMessageProps> = ({
  error,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const isShowBackButtonOnCartConflictsMessage = experiments.enabled(
    ExperimentsConsts.ShowBackButtonOnCartConflictsMessage,
  );

  return (
    <div
      className={classes.root}
      data-hook={CartConflictMessageDataHooks.NOTIFICATION_MESSAGE}
    >
      {isShowBackButtonOnCartConflictsMessage ? (
        <SectionMessage
          text={t('app.bookings-form.cart-conflict.error', error.params)}
          type={SectionMessageType.Error}
          withoutIcon
          buttonText={t('app.bookings-form.cart-conflict.back')}
          buttonOnClick={() => window.history.back()}
        />
      ) : (
        <SectionMessage type={SectionMessageType.Error} withoutIcon>
          <Trans
            i18nKey="app.bookings-form.toast.cart-conflict.error"
            values={error.params}
            components={{
              button: (
                <TextButton
                  className={classes.textButton}
                  onClick={() => {
                    window.history.back();
                  }}
                />
              ),
            }}
          ></Trans>
        </SectionMessage>
      )}
    </div>
  );
};
